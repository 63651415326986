import { useCallback, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { MdAdd } from "react-icons/md";
import { AttributeValuesSelect } from "../../../../components/selects/AttributeValuesSelect";
import { AttributesSelect } from "../../../../components/selects/AttributesSelect";
import { Attribute } from "../../../../models/Attribute";
import { AttributeType } from "../../../../models/AttributeType";
import { AttributeValuesFilters } from "../../../../models/filters/AttributeValuesFilters";
import {
  useReadResourceFromSelect,
  useReadResourcesFromSelect,
} from "../../../../resources/components/selects/hooks/useReadResourceFromSelect";
import { ResourceName } from "../../../../resources/types/resourceName";
import { AttributeFilterContent } from "../types/AttributeFilterContent";
import { ExtendedAutomaticArticlesSelection } from "../types/ExtendedAutomaticArticlesSelection";
import { AddRowProps } from "./AddRowProps";

export function AddAttributeRow({
  append,
}: AddRowProps<AttributeFilterContent>) {
  const { watch } = useFormContext<ExtendedAutomaticArticlesSelection>();

  const {
    resource: attribute,
    onResourceIdChanged: onAttributeIdChanged,
    setResourceId: setAttributeId,
    resourceId: attributeId,
  } = useReadResourceFromSelect<Attribute, ResourceName, number>({
    resourceName: "attributes",
  });

  const {
    onResourceIdsChanged: onAttributeValuesChanged,
    setResourceIds: setAttributeValues,
    resourceIds: attributeValues,
  } = useReadResourcesFromSelect<
    string,
    ResourceName,
    string,
    AttributeValuesFilters
  >({
    resourceName: "attribute-values",
    filters: attributeId != null ? { attributeId: attributeId } : undefined,
  });

  const onAppendAttributeValues = useCallback(() => {
    if (attribute && attributeValues && attributeValues.length > 0) {
      append({
        filterAttribute: {
          value: attribute.id.toString(),
          // label: attribute.name,
        },
        attributeType: attribute.type,
        filterAttributeValues: attributeValues
          ?.filter((a) => a != null)
          .map((a) => ({
            value: a,
          })),
      });
      setAttributeId(undefined);
      setAttributeValues(undefined);
    }
  }, [attribute, attributeValues, append, setAttributeId, setAttributeValues]);

  const attributes = watch("ex_attributes");

  const excludedIds = useMemo(
    () => attributes?.map((c) => c.filterAttribute.value),
    [attributes]
  );

  return (
    <tr>
      <td style={{ width: "50%" }}>
        <AttributesSelect
          idPropsValue={attributeId}
          excludedIds={excludedIds}
          onIdPropsValueChanged={onAttributeIdChanged}
        />
      </td>
      <td>
        {attribute && (
          <>
            {(Number(attribute.type) === AttributeType.Text ||
              Number(attribute.type) === AttributeType.Boolean) && (
              <AttributeValuesSelect
                attributeId={attribute.id}
                isMulti={true}
                idPropsValue={attributeValues}
                onIdPropsValueChanged={onAttributeValuesChanged}
              />
            )}
          </>
        )}
      </td>
      <td className="min">
        {attributeValues && (
          <Button
            variant="outline-primary"
            type="button"
            onClick={onAppendAttributeValues}
          >
            <MdAdd />
          </Button>
        )}
      </td>
    </tr>
  );
}
