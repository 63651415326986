import { useEffect, useMemo } from "react";
import useSWR from "swr";
import { useCurrentCustomer } from "../../LoggedInBackOffice";
import { ListProps } from "../types/dataProvider";
import { ResourceFilters } from "../types/resourceFilters";
import { useListResource } from "./useListResource";

type UseSWRListResourceProps<
  TResourceName extends string,
  TResourceFilters extends ResourceFilters
> = ListProps<TResourceName> & {
  filters?: TResourceFilters;
  canFetch?: boolean;
};
export const useSWRListResource = <
  TResourceName extends string = string,
  TResource = unknown,
  TResourceFilters extends ResourceFilters = ResourceFilters
>({
  resourceName,
  filters: filtersProps,
  pageIndex,
  canFetch = true,
  pageSize,
  ...props
}: UseSWRListResourceProps<TResourceName, TResourceFilters>) => {
  const { signal } = props;
  const filters = useMemo(
    () => ({ ...filtersProps, currentPageIndex: pageIndex, pageSize }),
    [filtersProps, pageIndex, pageSize]
  );
  const { list } = useListResource<TResourceName, TResource>({
    canThrow: true,
    filters,
  });

  const customer = useCurrentCustomer();
  return useSWR(
    canFetch
      ? resourceName + customer?.id + JSON.stringify(filters)
      : undefined,
    async () => await list({ resourceName, signal })
  );
};
